import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import eventService from "services/eventService";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Navbar from "sections/Navbars";
import Footer from "sections/Footers";
import Header from "sections/Headers";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import frLocale from "@fullcalendar/core/locales/fr";
import bgImage from "assets/imgs/headers/bg-calendar.jpg";
import routes from "routes";
import footerRoutes from "footer.routes";

function Calendar() {
  const [events, setEvents] = useState([]);
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const navigate = useNavigate();

  const fetchEvents = async (start, end) => {
    try {
      const eventsData = await eventService.getEventsInRange(start, end);
      const transformedEvents = eventsData.map((event) => ({
        title: event.title,
        start: event.startDate,
        end: event.endDate,
        allDay: event.allDay,
        extendedProps: {
          slug: event.slug,
          description: event.description,
        },
      }));
      setEvents(transformedEvents);
    } catch (error) {
      console.error("Erreur lors de la récupération des événements :", error);
    }
  };

  // Handler appelé quand les dates visibles changent
  const handleDatesSet = (dateInfo) => {
    const start = new Date(dateInfo.start).toISOString();
    const end = new Date(dateInfo.end).toISOString();

    // Vérifie si la plage de dates a changé
    if (start !== dateRange.start || end !== dateRange.end) {
      setDateRange({ start, end });
    }
  };

  // Utilisez useEffect pour appeler fetchEvents lorsque dateRange change
  useEffect(() => {
    if (dateRange.start && dateRange.end) {
      fetchEvents(dateRange.start, dateRange.end);
    }
  }, [dateRange]);

  const breadcrumbs = [
    { label: "Accueil", route: "/" },
    { label: "Calendrier", route: "/calendrier" },
  ];

  return (
    <>
      <Navbar routes={routes} sticky />
      <Header bgImage={bgImage} title="Calendrier" breadcrumbs={breadcrumbs} />
      <Container sx={{ mt: -8, mb: 4 }}>
        <Card sx={{ p: 2, mx: { xs: 2, lg: 3 }, boxShadow: ({ boxShadows: { xxl } }) => xxl }}>
          <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <MKTypography variant="h6">Calendrier</MKTypography>
          </MKBox>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
            initialView={window.innerWidth < 768 ? "listMonth" : "dayGridMonth"}
            events={events}
            locales={[frLocale]}
            locale="fr"
            headerToolbar={{
              left: window.innerWidth < 768 ? "prev,next" : "prev,next today",
              center: "title",
              right: window.innerWidth < 768 ? "" : "dayGridMonth,listMonth",
            }}
            height="auto"
            datesSet={handleDatesSet}
            eventContent={(eventInfo) => (
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                }}
                className="calendarEvent"
              >
                <b>{eventInfo.timeText}</b> <i>{eventInfo.event.title}</i>
              </div>
            )}
            eventClick={(info) => {
              navigate(`/calendrier/${info.event.extendedProps.slug}`);
            }}
          />
        </Card>
      </Container>
      <MKBox pt={6} px={1} mt={6}>
        <Footer content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Calendar;
