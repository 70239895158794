// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import Navbar from "sections/Navbars";
import Footer from "sections/Footers";
import Header from "sections/Headers";

import Information from "pages/About/sections/Information";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/imgs/headers/bg-about-us.jpg";

const breadcrumbs = [
  { label: "Accueil", route: "/" },
  { label: "A propos", route: "/a-propos" },
];

function AboutUs() {
  return (
    <>
      <Navbar routes={routes} sticky />
      <Header bgImage={bgImage} title="A propos de nous" breadcrumbs={breadcrumbs} />
      <Container sx={{ mt: -8, mb: 4 }}>
        <Card sx={{ p: 2, mx: { xs: 2, lg: 3 }, boxShadow: ({ boxShadows: { xxl } }) => xxl }}>
          <Information />
        </Card>
      </Container>
      <MKBox pt={6} px={1} mt={6}>
        <Footer content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
