import React from "react";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const ArticleCard = ({ title, content, author, date, slug }) => {
  const contentPreview = content.length > 190 ? content.substring(0, 190) + "..." : content;

  return (
    <MKBox p={2} border="1px solid #eee" borderRadius="8px" mb={3}>
      <MKTypography variant="h4" gutterBottom>
        {title}
      </MKTypography>
      <MKTypography variant="body1" mt={2}>
        <div dangerouslySetInnerHTML={{ __html: contentPreview }} />
      </MKTypography>
      <MKBox display="flex" justifyContent="flex-end" mt={1}>
        <MKTypography variant="button" color="info" component={Link} to={`/article/${slug}`}>
          Lire l&apos;article complet
        </MKTypography>
      </MKBox>
      <MKTypography variant="body2" color="textSecondary">
        <strong>Auteur :</strong> {author} | <strong>Date :</strong>
        {new Date(date).toLocaleDateString()}
      </MKTypography>
    </MKBox>
  );
};

// Validation des props avec PropTypes
ArticleCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

export default ArticleCard;
