// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import Navbar from "sections/Navbars";
import Footer from "sections/Footers";
import Header from "sections/Headers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/imgs/headers/bg-blog.jpg";

// React imports
import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

// Material UI Icons
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";

// Article service
import articleService from "services/articleService"; // Assurez-vous d'avoir créé ce service

const Article = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);
  const [previousArticle, setPreviousArticle] = useState(null);
  const [nextArticle, setNextArticle] = useState(null);

  useEffect(() => {
    const fetchArticleWithNeighbors = async () => {
      try {
        const { currentArticle, previousArticle, nextArticle } =
          await articleService.getArticleWithNeighbors(slug);
        setArticle(currentArticle);
        setPreviousArticle(previousArticle);
        setNextArticle(nextArticle);
      } catch (error) {
        console.error("Erreur lors de la récupération de l'article :", error);
      }
    };

    fetchArticleWithNeighbors();
  }, [slug]);

  if (!article) {
    return (
      <MKBox display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <MKTypography variant="h4">Chargement de l&apos;article...</MKTypography>
      </MKBox>
    );
  }

  const breadcrumbs = [
    { label: "Accueil", route: "/" },
    { label: "Blog", route: "/blog" },
    { label: article.title },
  ];

  return (
    <>
      <Navbar routes={routes} sticky />
      <Header bgImage={bgImage} title={article.title} breadcrumbs={breadcrumbs} />
      <Container sx={{ mt: -8, mb: 4 }}>
        <Card sx={{ p: 3, mx: { xs: 2, lg: 3 }, boxShadow: ({ boxShadows: { xxl } }) => xxl }}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={10} lg={8}>
              <MKBox p={2} className="entry-content">
                <MKTypography
                  variant="h3"
                  component="div"
                  mb={3}
                  dangerouslySetInnerHTML={{ __html: article.title }}
                />
                <MKTypography
                  variant="body1"
                  component="div"
                  dangerouslySetInnerHTML={{ __html: article.content }}
                />
                <MKTypography variant="body2" color="textSecondary" mb={1}>
                  {article.author}, le {new Date(article.date).toLocaleDateString()}
                  &nbsp;à&nbsp;{new Date(article.date).toLocaleTimeString().substring(0, 5)}
                </MKTypography>
              </MKBox>
              <MKBox display="flex" justifyContent="space-between" mt={4}>
                <MKBox>
                  {previousArticle ? (
                    <Link to={`/article/${previousArticle.slug}`}>
                      <MKTypography variant="body2" color="primary">
                        <ArrowBack sx={{ verticalAlign: "middle", mr: 0.5 }} />
                        {previousArticle.title}
                      </MKTypography>
                    </Link>
                  ) : (
                    <span />
                  )}
                </MKBox>

                <MKBox textAlign="right">
                  {nextArticle && (
                    <Link to={`/article/${nextArticle.slug}`}>
                      <MKTypography variant="body2" color="primary">
                        {nextArticle.title}
                        <ArrowForward sx={{ verticalAlign: "middle", ml: 0.5 }} />
                      </MKTypography>
                    </Link>
                  )}
                </MKBox>
              </MKBox>
            </Grid>
          </Grid>
        </Card>
      </Container>
      <MKBox pt={6} px={1} mt={6}>
        <Footer content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default Article;
