import { Link } from "react-router-dom";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import PropTypes from "prop-types";

const Navigation = ({ previousEvent, nextEvent }) => (
  <MKBox display="flex" justifyContent="space-between" mt={4}>
    <MKBox>
      {previousEvent ? (
        <Link to={`/calendrier/${previousEvent.slug}`}>
          <MKTypography variant="body2" color="primary">
            <ArrowBack sx={{ verticalAlign: "middle", mr: 0.5 }} />
            {previousEvent.title}
          </MKTypography>
        </Link>
      ) : (
        <MKTypography variant="body2" color="textSecondary">
          Pas d&apos;événement précédent
        </MKTypography>
      )}
    </MKBox>
    <MKBox>
      {nextEvent ? (
        <Link to={`/calendrier/${nextEvent.slug}`}>
          <MKTypography variant="body2" color="primary">
            {nextEvent.title}
            <ArrowForward sx={{ verticalAlign: "middle", ml: 0.5 }} />
          </MKTypography>
        </Link>
      ) : (
        <MKTypography variant="body2" color="textSecondary">
          Pas d&apos;événement suivant
        </MKTypography>
      )}
    </MKBox>
  </MKBox>
);

Navigation.propTypes = {
  previousEvent: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  nextEvent: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
};

export default Navigation;
