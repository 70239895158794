import MKTypography from "components/MKTypography";
import Divider from "@mui/material/Divider";
import MKBox from "components/MKBox";
import PropTypes from "prop-types";

const Details = ({ event, formatEventDates }) => (
  <MKBox p={2} className="entry-content">
    <MKTypography variant="h3">{event.title}</MKTypography>
    <MKTypography variant="body1" color="textSecondary">
      {formatEventDates()}
    </MKTypography>

    <Divider sx={{ my: 3 }} />

    <MKTypography
      variant="body1"
      component="div"
      dangerouslySetInnerHTML={{ __html: event.description }}
    />

    <Divider sx={{ my: 3 }} />

    <MKTypography variant="h5" mt={2} mb={1}>
      Informations Complémentaires
    </MKTypography>
    <MKTypography variant="body2" color="textSecondary" mb={1}>
      <strong>Date(s) et heures :</strong> {formatEventDates()}
    </MKTypography>
    {event.locationManual && (
      <MKTypography variant="body2" color="textSecondary" mb={1}>
        <strong>Lieu :</strong> {event.locationManual}
      </MKTypography>
    )}
    {event.price !== undefined && (
      <MKTypography variant="body2" color="textSecondary" mb={1}>
        <strong>Prix :</strong> {event.price ? `${event.price}€` : "Gratuit"}
      </MKTypography>
    )}
    {event.organizerManual && (
      <MKTypography variant="body2" color="textSecondary" mb={1}>
        <strong>Organisateur :</strong> {event.organizerManual}
      </MKTypography>
    )}
    {event.url && (
      <MKTypography variant="body2" color="textSecondary" mb={1}>
        <strong>Site web :</strong>
        <a href={event.url} target="_blank" rel="noopener noreferrer">
          {event.url}
        </a>
      </MKTypography>
    )}
    {event.email && (
      <MKTypography variant="body2" color="textSecondary" mb={1}>
        <strong>Email :</strong> <a href={`mailto:${event.email}`}>{event.email}</a>
      </MKTypography>
    )}
  </MKBox>
);

Details.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    locationManual: PropTypes.string,
    price: PropTypes.number,
    organizerManual: PropTypes.string,
    url: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  formatEventDates: PropTypes.func.isRequired,
};

export default Details;
