import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";

import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import ArticleCard from "sections/Cards/ArticleCard";
import articleService from "services/articleService";

const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 5;

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const data = await articleService.getArticles();
        // Trie les articles du plus récent au plus ancien
        const sortedArticles = data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setArticles(sortedArticles);
      } catch (error) {
        console.error("Erreur lors de la récupération des articles :", error);
      }
    };

    fetchArticles();
  }, []);

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);

  const totalPages = Math.ceil(articles.length / articlesPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid
            item
            xs={12}
            textAlign="center"
            sx={{
              mb: { xs: 0, sm: 3 },
            }}
          >
            <MKTypography variant="h1">Nos derniers articles</MKTypography>
          </Grid>
          {currentArticles.map((article) => (
            <Grid
              item
              xs={12}
              sm={10}
              sx={{
                mb: { xs: 0, sm: 3 },
              }}
              key={article._id}
            >
              <ArticleCard
                title={article.title}
                content={article.content}
                author={article.author}
                date={article.date}
                slug={article.slug}
              />
            </Grid>
          ))}
        </Grid>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          style={{ marginTop: "20px", justifyContent: "center", display: "flex" }}
        />
      </Container>
    </MKBox>
  );
};

export default Articles;
