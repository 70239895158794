// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import CenteredBlogCard from "sections/Cards/CenteredBlogCard";

import logo from "assets/imgs/logo-512.png";

function Information() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={7}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12}>
                <MKBox mb={3}>
                  <MKTypography variant="h1">Les Enfants de la Rigole</MKTypography>
                  <MKTypography variant="body2" mt={3}>
                    Créée en 2024, « Les Enfants de la Rigole » est une association dédiée au
                    développement et au bien-être des enfants des villages de Villiers-le-Bâcle et
                    de Saint-Aubin. Son objectif est simple : offrir aux enfants un environnement
                    propice à leur épanouissement, en favorisant des activités éducatives,
                    culturelles, sportives et sociales qui renforcent le lien entre les jeunes et
                    leur territoire.
                  </MKTypography>
                </MKBox>
              </Grid>
              <Grid item xs={12}>
                <MKBox>
                  <Grid container spacing={1} justifyContent="flex-start">
                    <Grid item xs={12}>
                      <MKTypography variant="h4">Nos missions</MKTypography>
                    </Grid>
                    <Grid item xs={12} md={6} mt={1}>
                      <MKBox>
                        <MKTypography variant="h6">
                          Organiser des activités et des événements
                        </MKTypography>
                        <MKTypography variant="body2" mt={0}>
                          pour les enfants, leur permettant de découvrir de nouvelles passions, de
                          s’ouvrir aux autres et de s’amuser dans un cadre sécurisé.
                        </MKTypography>
                      </MKBox>
                    </Grid>
                    <Grid item xs={12} md={6} mt={1}>
                      <MKBox>
                        <MKTypography variant="h6">Créer un réseau de solidarité</MKTypography>
                        <MKTypography variant="body2" mt={0}>
                          entre les familles, les bénévoles et les institutions locales pour
                          soutenir l’épanouissement des plus jeunes.
                        </MKTypography>
                      </MKBox>
                    </Grid>
                    <Grid item xs={12} mt={1}>
                      <MKBox>
                        <MKTypography variant="h6">
                          Favoriser la cohésion entre les deux villages
                        </MKTypography>
                        <MKTypography variant="body2" mt={0}>
                          en impliquant les enfants et leurs parents dans des projets collectifs.
                        </MKTypography>
                      </MKBox>
                    </Grid>
                  </Grid>
                </MKBox>
              </Grid>
              <Grid item xs={12} mt={3}>
                <MKBox>
                  <MKTypography variant="h5">
                    Pourquoi rejoindre « Les Enfants de la Rigole » ?
                  </MKTypography>
                  <MKTypography variant="body2" mt={1}>
                    Rejoindre notre association, c’est non seulement offrir des moments inoubliables
                    à vos enfants, mais aussi faire partie d’une aventure collective où chacun,
                    parent ou enfant, peut apporter sa pierre à l’édifice. En participant à nos
                    événements ou en devenant bénévole, vous contribuez à rendre nos villages encore
                    plus vivants et solidaires.
                  </MKTypography>
                </MKBox>
              </Grid>
              <Grid item xs={12} mt={1}>
                <MKBox>
                  <MKTypography variant="body2" mt={0}>
                    Nous croyons fermement que chaque enfant mérite de s’épanouir dans un
                    environnement riche en découvertes, en apprentissages et en partages. C’est
                    pourquoi « Les Enfants de la Rigole » s’engage à créer des moments inoubliables
                    et à tisser des liens durables entre les générations.
                  </MKTypography>
                </MKBox>
              </Grid>
              <Grid item xs={12} mt={3}>
                <MKBox>
                  <MKTypography variant="h3">Ensemble, faisons grandir nos enfants !</MKTypography>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              title=""
              image={logo}
              action={{
                type: "external",
                route:
                  "https://www.helloasso.com/associations/les-enfants-de-la-rigole/adhesions/adhesion-association-les-enfants-de-la-rigole",
                color: "info",
                label: "Nous rejoindre",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
