import React from "react";
import MKBox from "components/MKBox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import Breadcrumbs from "sections/Breadcrumbs";
import PropTypes from "prop-types";

const Header = ({ bgImage, title, breadcrumbs }) => (
  <MKBox
    width="100%"
    sx={{
      backgroundImage: `url(${bgImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "grid",
      placeItems: "center",
      minHeight: { xs: "250px", md: "50vh" },
    }}
  >
    <Container>
      <Grid
        container
        item
        xs={12}
        lg={8}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        sx={{ mx: "auto", textAlign: "center" }}
      >
        <MKTypography
          variant="h1"
          color="white"
          className="blackTextBorder"
          sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
              fontSize: size["3xl"],
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: "90vw",
            },
            fontSize: size["4xl"],
            maxHeight: { xs: "6vh", md: "20vh" },
            whiteSpace: "normal",
          })}
        >
          {title}
        </MKTypography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        lg={8}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        sx={{ mx: "auto", textAlign: "center", mt: { xs: 2, md: 8 } }}
      >
        <Breadcrumbs routes={breadcrumbs} />
      </Grid>
    </Container>
  </MKBox>
);

Header.propTypes = {
  bgImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      route: PropTypes.string,
    })
  ).isRequired,
};

export default Header;
