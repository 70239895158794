// sections/UpcomingEvents.js
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import eventService from "services/eventService";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";

function UpcomingEvents({ maxEvents = 5 }) {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const upcomingEvents = await eventService.getUpcomingEvents(maxEvents);
        setEvents(upcomingEvents);
      } catch (err) {
        setError("Impossible de charger les événements");
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [maxEvents]);

  if (loading) return <Typography>Chargement des événements...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;
  if (events.length === 0) return <Typography>Aucun événement à venir</Typography>;

  return (
    <Card sx={{ p: 2 }}>
      <Typography variant="h4" component="h2" gutterBottom mb={2}>
        Événements à venir
      </Typography>
      <List>
        {events.map((event, index) => (
          <React.Fragment key={event.slug}>
            <ListItem
              button
              onClick={() => navigate(`/calendrier/${event.slug}`)} // Navigate to event details
              sx={{ cursor: "pointer" }}
            >
              <List>
                <ListItem>
                  <Typography variant="h6">
                    Le {new Date(event.startDate).toLocaleDateString()}&nbsp;à&nbsp;
                    {event.locationManual || "Lieu non spécifié"}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body2">{event.title}</Typography>
                </ListItem>
              </List>
            </ListItem>
            {index < events.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Card>
  );
}

UpcomingEvents.propTypes = {
  maxEvents: PropTypes.number,
};

export default UpcomingEvents;
