import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const EVENTS_URL = `${BASE_URL}/api/events`;
const API_KEY = process.env.REACT_APP_API_KEY;

const getAuthHeaders = () => {
  return { "X-API-KEY": API_KEY };
};

const getEvents = async () => {
  const response = await axios.get(EVENTS_URL, { headers: getAuthHeaders() });
  return response.data;
};

// Récupérer un événement par son slug
const getEventBySlug = async (slug) => {
  const response = await axios.get(`${EVENTS_URL}/${slug}`);
  return response.data;
};

// Créer un nouvel événement
const createEvent = async (eventData) => {
  const response = await axios.post(EVENTS_URL, eventData);
  return response.data;
};

// Récupérer un événement avec ses articles voisins
const getEventWithNeighbors = async (slug) => {
  const response = await axios.get(`${EVENTS_URL}/${slug}/neighbors`);
  return response.data;
};

// Récupérer les événements dans une plage de dates
const getEventsInRange = async (start, end) => {
  const response = await axios.get(`${EVENTS_URL}?start=${start}&end=${end}`, {
    headers: getAuthHeaders(),
  });
  return response.data;
};

// Récupérer les événements à venir avec une limite
const getUpcomingEvents = async (limit = 5) => {
  const response = await axios.get(`${EVENTS_URL}/upcoming?limit=${limit}`, {
    headers: getAuthHeaders(),
  });
  return response.data;
};

export default {
  getEvents,
  createEvent,
  getEventBySlug,
  getEventWithNeighbors,
  getEventsInRange,
  getUpcomingEvents,
};
