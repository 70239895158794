import React from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Navbar from "sections/Navbars";
import Footer from "sections/Footers";
import Header from "sections/Headers";
import Presentation from "./sections/Presentation";
import RecentArticles from "./sections/RecentArticles";
import UpcomingEvents from "./sections/UpcomingEvents";
import routes from "routes";
import footerRoutes from "footer.routes";
import bgImage from "assets/imgs/headers/bg-about-us.jpg";

const breadcrumbs = [{ label: "Accueil", route: "/" }];

function Home() {
  return (
    <>
      <Navbar routes={routes} sticky />
      <Header bgImage={bgImage} title="Accueil" breadcrumbs={breadcrumbs} />
      <Container sx={{ mt: -8, mb: 4 }}>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Presentation />
              <RecentArticles />
            </Grid>
            <Grid item xs={12} md={4}>
              <UpcomingEvents />
            </Grid>
          </Grid>
        </Card>
      </Container>
      <Footer content={footerRoutes} />
    </>
  );
}

export default Home;
