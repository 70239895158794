import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import IconButton from "@mui/material/IconButton";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import MKTypography from "components/MKTypography";
import Lightbox from "react-image-lightbox";
import { useState } from "react";
import PropTypes from "prop-types";

const Medias = ({ media }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <>
      <MKTypography variant="h5" mt={2} mb={1}>
        Médias
      </MKTypography>
      <Grid container spacing={2}>
        {media.map((mediaItem, index) => (
          <Grid item xs={3} key={index}>
            {mediaItem.url.endsWith(".jpg") || mediaItem.url.endsWith(".png") ? (
              <MKBox
                component="img"
                src={mediaItem.url}
                alt={`media-${index}`}
                width="100%"
                onClick={() => openLightbox(index)}
                sx={{ cursor: "pointer" }}
              />
            ) : (
              <MKBox display="flex" flexDirection="column" alignItems="center">
                <IconButton href={mediaItem.url} target="_blank" download>
                  <InsertDriveFileIcon />
                </IconButton>
                <MKTypography variant="body2">{mediaItem.label}</MKTypography>
              </MKBox>
            )}
          </Grid>
        ))}
      </Grid>

      {isOpen && (
        <Lightbox
          mainSrc={media[photoIndex].url}
          nextSrc={media[(photoIndex + 1) % media.length].url}
          prevSrc={media[(photoIndex + media.length - 1) % media.length].url}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + media.length - 1) % media.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % media.length)}
        />
      )}
    </>
  );
};

Medias.propTypes = {
  media: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      label: PropTypes.string,
      type: PropTypes.oneOf(["image", "document"]),
    })
  ).isRequired,
};

export default Medias;
