import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const ARTICLES_URL = `${BASE_URL}/api/articles`;
const API_KEY = process.env.REACT_APP_API_KEY;

const getAuthHeaders = () => {
  return { "X-API-KEY": API_KEY };
};

const getArticles = async () => {
  const response = await axios.get(ARTICLES_URL, { headers: getAuthHeaders() });
  return response.data;
};

// Récupérer un article par son slug
const getArticleBySlug = async (slug) => {
  const response = await axios.get(`${ARTICLES_URL}/${slug}`);
  return response.data;
};

// Créer un nouvel article
const createArticle = async (articleData) => {
  const response = await axios.post(ARTICLES_URL, articleData);
  return response.data;
};

// Récupérer un article avec ses articles voisins
const getArticleWithNeighbors = async (slug) => {
  const response = await axios.get(`${ARTICLES_URL}/${slug}/neighbors`);
  return response.data;
};

// Récupérer les événements à venir avec une limite
const getRecentsArticles = async (limit = 2) => {
  const response = await axios.get(`${ARTICLES_URL}/recents?limit=${limit}`, {
    headers: getAuthHeaders(),
  });
  return response.data;
};

export default {
  getArticles,
  createArticle,
  getArticleBySlug,
  getArticleWithNeighbors,
  getRecentsArticles,
};
