import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import MKTypography from "components/MKTypography";
import Navbar from "sections/Navbars";
import Footer from "sections/Footers";
import Header from "sections/Headers";
import eventService from "services/eventService";

import Details from "./sections/Details";
import Medias from "./sections/Medias";
import Navigation from "./sections/Navigation";
import routes from "routes";

import bgImage from "assets/imgs/headers/bg-blog.jpg";

const Event = () => {
  const { slug } = useParams();
  const [event, setEvent] = useState(null);
  const [previousEvent, setPreviousEvent] = useState(null);
  const [nextEvent, setNextEvent] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const { currentEvent, previousEvent, nextEvent } = await eventService.getEventWithNeighbors(
          slug
        );
        setEvent(currentEvent);
        setPreviousEvent(previousEvent);
        setNextEvent(nextEvent);
      } catch (error) {
        console.error("Error fetching event data: ", error);
      }
    };

    fetchEvent();
  }, [slug]);

  const formatEventDates = () => {
    if (!event) return "";
    const start = new Date(event.startDate);
    const end = event.endDate ? new Date(event.endDate) : null;

    let dateStr = `${start.toLocaleDateString()} ${start.toLocaleTimeString()}`;
    if (end) {
      dateStr += ` - ${end.toLocaleDateString()} ${end.toLocaleTimeString()}`;
    }
    return dateStr;
  };

  if (!event) {
    return <MKTypography variant="body1">Chargement de l&apos;événement...</MKTypography>;
  }

  const breadcrumbs = [
    { label: "Accueil", route: "/" },
    { label: "Calendrier", route: "/calendrier" },
    { label: event.title }, // Assurez-vous que event.title existe avant l'accès
  ];

  return (
    <>
      <Navbar routes={routes} sticky />
      {event && <Header bgImage={bgImage} title={event.title} breadcrumbs={breadcrumbs} />}
      <Container sx={{ mt: -8, mb: 4 }}>
        <Card sx={{ p: 2, mx: { xs: 2, lg: 3 }, boxShadow: ({ boxShadows: { xxl } }) => xxl }}>
          <Details event={event} formatEventDates={formatEventDates} />
          <Medias media={event.media} />
          <Navigation previousEvent={previousEvent} nextEvent={nextEvent} />
        </Card>
      </Container>
      <Footer />
    </>
  );
};

export default Event;
