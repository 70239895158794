import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import logo from "assets/imgs/logo-512.png";

function Presentation() {
  return (
    <Card sx={{ p: 2, mb: 3 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={5}>
          <MKBox display="flex" justifyContent="center">
            <img
              src={logo}
              alt="Logo de l'association"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </MKBox>
        </Grid>
        <Grid item xs={12} md={7}>
          <MKTypography variant="h3">Les Enfants de la Rigole</MKTypography>
          <MKTypography variant="body2" mt={3}>
            Depuis 2024, notre association soutient les enfants de Villiers-le-Bâcle et Saint-Aubin.
            Nous organisons des activités éducatives et ludiques pour encourager leur
            épanouissement, en tissant des liens entre familles et villages.
          </MKTypography>
          <MKBox display={{ xs: "none", md: "block" }}>
            <MKTypography variant="body2" mt={3}>
              <strong>Pourquoi nous rejoindre ?</strong> Rejoindre &quot; Les Enfants de la
              Rigole&quot; permet aux familles de vivre des moments partagés, tout en contribuant à
              une communauté solidaire.
            </MKTypography>
          </MKBox>
        </Grid>
      </Grid>
    </Card>
  );
}

export default Presentation;
