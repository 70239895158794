import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import articleService from "services/articleService";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";

function RecentArticles({ maxArticles = 2 }) {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setLoading(true);
        const upcomingArticles = await articleService.getRecentsArticles(maxArticles);
        setArticles(upcomingArticles);
      } catch (err) {
        setError("Impossible de charger les articles");
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [maxArticles]);

  if (loading) return <Typography>Chargement des articles...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;
  if (articles.length === 0) return <Typography>Aucun article récent</Typography>;

  return (
    <Card sx={{ p: 2 }}>
      <Typography variant="h4" component="h2" gutterBottom mb={2}>
        Nos derniers articles
      </Typography>
      <List>
        {articles.map((article, index) => (
          <React.Fragment key={article.slug}>
            <ListItem
              button
              onClick={() => navigate(`/article/${article.slug}`)}
              sx={{ cursor: "pointer" }}
            >
              <List>
                <ListItem>
                  <Typography variant="h6">
                    Le {new Date(article.date).toLocaleDateString()}&nbsp;par&nbsp;
                    {article.author || "N/A"}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body2">{article.title}</Typography>
                </ListItem>
              </List>
            </ListItem>
            {index < articles.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Card>
  );
}

RecentArticles.propTypes = {
  maxArticles: PropTypes.number,
};

export default RecentArticles;
